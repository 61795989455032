import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['searchBox', 'dropdown', 'searchForm', 'loadingData', 'organizationForm',  'organizationName', 'organizationKvk', 'organizationWebsite', 'organizationPostcode', 'organizationNumber', 'organizationNumberAddition', 'organizationStreet', 'organizationCity'];
    connect() {
        this.loading = this.dropdownTarget.innerHTML;
        this.dropdownTarget.innerHTML = '';
    }

    typing() {
        if(!this.isTyping) {
            this.isTyping = true
        }

        this.stopTypingTimer
        console.log('testing testing.......')
        if (this.stopTypingTimer) {
            clearTimeout(this.stopTypingTimer)
        }
        console.log('testing testing......2')
        this.stopTypingTimer = setTimeout(this.stoppedTyping.bind(this), 1000)
        console.log('testing testing......3')
    }

    stoppedTyping() {
        if (!this.isTyping) {
            return;
        }
        this.isTyping = false
        this.search();
    }

    async search() {
        let searchQuery;
        if (this.organizationKvkTarget.value.length !== 0) { // Place: sign-up flow
             searchQuery = this.organizationKvkTarget.value
        } else if (this.organizationNameTarget.value.length !== 0) { // Place: sign-up flow
            searchQuery = this.organizationNameTarget.value
        } else if (this.searchBoxTarget.value.length !== 0) { // Place: logged in add organization
            searchQuery = this.searchBoxTarget.value
        } else {
            return;
        }
        console.log(searchQuery);

        this.dropdownTarget.innerHTML = this.loading;

        this.showDropdown();
        let data = {
            headers: new Headers({'X-STIMULUS-REQUEST': true}),
            method: 'POST',
            body: searchQuery,
        };

        const response = await fetch('/api-internal/kvk/search', data);

        this.dropdownTarget.innerHTML = await response.text();
    }

    async selected(/**Event*/ event) {
        event.preventDefault();
        // this.searchFormTarget.classList.add('d-none');
        // this.loadingDataTarget.classList.remove('d-none');
        let data = {
            headers: new Headers({'X-STIMULUS-REQUEST': true}),
            method: 'POST',
            body: (event.target.tagName === 'A') ? event.target.dataset.kvk : event.target.closest('a').dataset.kvk
        };

        try {
            const response = await fetch('/api-internal/kvk/get', data);

            let kvkData = await response.json();
            this.organizationNameTarget.value = kvkData.name;
            this.organizationKvkTarget.value = kvkData.number;
            this.organizationWebsiteTarget.value = kvkData.website;
            this.organizationPostcodeTarget.value = kvkData.address.postalCode;
            this.organizationNumberTarget.value = kvkData.address.number;
            this.organizationNumberAdditionTarget.value = kvkData.address.numberAdddition;
            this.organizationStreetTarget.value = kvkData.address.street;
            this.organizationCityTarget.value = kvkData.address.city;
        } catch (error) {
            alert('er ging iets mis')
            throw error
        }

        this.hideDropdown();
        this.showForm()
    }

    showDropdown() {
        this.dropdownTarget.classList.remove('d-none')
    }

    hideDropdown() {
        let el = this.dropdownTarget;
        setTimeout(function () {el.classList.add('d-none')}, 200)
    }

    showForm() {
        // this.searchFormTarget.classList.add('d-none');
        // this.loadingDataTarget.classList.add('d-none');
        this.organizationFormTarget.classList.remove('d-none');
    }
}
