import {Controller} from '@hotwired/stimulus';
import { nl } from 'timeago.js/lib/lang';
import { register, render, cancel } from 'timeago.js';


export default class extends Controller {
    static values = {
        diploma: String,
        diplomaSuggestion: String,
        qualification: String,
        mandatory: Boolean,
    }
    static targets = ['status', 'assignedAt', 'expiresAt', 'organization', 'instructor'];

    async connect() {
        register('nl_NL', nl);
        let url = null;

        if (this.diplomaValue !== '') {
            const uri = '/api/person/diploma/status/{diploma}/{qualification}?_locale={locale}';
            url = uri.replace('{diploma}', this.diplomaValue).replace('{qualification}', this.qualificationValue).replace('{locale}', document.documentElement.lang);
        } else if(this.diplomaSuggestionValue !== '') {
            const uri = '/api/person/diploma-suggestion/status/{diplomaSuggestion}/{qualification}?_locale={locale}';
            url = uri.replace('{diplomaSuggestion}', this.diplomaSuggestionValue).replace('{qualification}', this.qualificationValue).replace('{locale}', document.documentElement.lang);
        } else {
            throw new Error('Could not determine url');
        }

        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();
            this.update(data);
        } else {
            this.statusTarget.classList.remove('fa-spin', 'fa-spinner');
            this.statusTarget.classList.add('fa-exclamation-triangle');
        }
    }

    update(data) {
        this.statusTarget.classList.remove('fa-spin', 'fa-spinner');
        if (!data.valid) {
            if (this.mandatoryValue !== '' && this.mandatoryValue === true) {
                this.statusTarget.classList.add('fa-times', 'text-danger');
            } else {
                this.statusTarget.classList.add('fa-times', 'text-success');
            }
        } else if (data.expiresSoon) {
            this.statusTarget.classList.add('fa-exclamation', 'text-warning');
        } else {
            this.statusTarget.classList.add('fa-check', 'text-success');
        }

        if (typeof data.assignedAt !== "undefined" && data.assignedAt !== null) {
            const assignedAt = new Date(data.assignedAt);
            const expiresAt = new Date(data.expiresAt);

            this.assignedAtTarget.innerHTML = assignedAt.toLocaleDateString();
            this.expiresAtTarget.innerHTML = expiresAt.toLocaleDateString();
            this.instructorTarget.innerHTML = data.instructor;
            if (data.organizationURL !== null) {
                this.organizationTarget.innerHTML = '<a href="' + data.organizationURL+'" target="_blank">' + data.organization + '</a>';
            } else {
                this.organizationTarget.innerHTML = data.organization;
            }


        }

        this.assignedAtTarget.classList.remove('d-none');
    }

    disconnect() {
        cancel(this.element)
    }
}
