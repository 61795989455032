import {Controller} from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
    static targets = ['modal', 'modalTitle', 'modalBody', 'footerHelper', 'submitButton', 'closeButton', 'linkButton'];
    static values = {
        formUrl: String,
        loadingBody: String,
    }

    connect() {
        this.loadingBody = this.modalBodyTarget.innerHTML;
    }

    showLoader() {
        this.modalBodyTarget.innerHTML = this.loadingBody;
    }

    async openModal(event) {
        this.submitButtonTarget.classList.remove('d-none');
        this.closeButtonTarget.classList.add('d-none', 'btn-primary');


        if (typeof event.currentTarget.href !== "undefined") {
            this.formUrlValue = event.currentTarget.href;
            event.preventDefault();
            event.stopImmediatePropagation();
        } else if (event.currentTarget.hasAttribute('data-url')) {
            this.formUrlValue = event.currentTarget.getAttribute('data-url');
        }

        this.showLoader();
        this.modal = new Modal(this.modalTarget);
        this.modal.show();

        const response = await fetch(this.formUrlValue);
        this.modalBodyTarget.innerHTML = await response.text();
    }

    updateFooterHelper(event) {
        this.footerHelperTarget.innerHTML = event.detail;
    }

    async submitForm(event) {
        event.preventDefault();
        const form = this.modalBodyTarget.getElementsByTagName('form')[0];
        let data = {
            headers: new Headers({'X-STIMULUS-REQUEST': true}),
            method: form.method,
            body: new URLSearchParams(new FormData(form)),
        };

        this.showLoader();
        const response = await fetch(this.formUrlValue, data);
        if (response.ok) {
            this.modalBodyTarget.innerHTML = await response.text();
            this.submitButtonTarget.classList.add('d-none');
            this.closeButtonTarget.classList.remove('d-none');
        } else {
            this.modalBodyTarget.innerHTML = await response.text();
        }
    }
}
