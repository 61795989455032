import {Controller} from '@hotwired/stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
    static values = {
        title: String,
        text: String,
        confirmButtonText: String,
    }

    connect() {
        const title = this.titleValue;
        const text = this.textValue;
        const confirmButton =  this.confirmButtonTextValue;

        this.element.addEventListener('click', function (event){
            event.preventDefault();
            Swal.fire({
                title: title || null,
                text: text || null,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: confirmButton || 'Yes',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const response = await fetch(event.target.href, { method: 'DELETE' });
                    if (response.ok) {
                        location.reload();
                        return true;
                    } else {
                        return false;
                    }
                }
            });
        });
    }

    disconnect() {
        this.element.removeEventListener('click');
        super.disconnect();
    }
}
