import {Controller} from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
    static targets = ['modal', 'modalTitle', 'modalBody', 'footerHelper', 'submitButton', 'closeButton', 'linkButton'];
    static values = {
        formUrl: String,
        loadingBody: String,
    }
    connect() {
        this.loadingBody = this.modalBodyTarget.innerHTML;
            this.loadingSubmitButtonText = this.submitButtonTarget.innerHTML;
    }

    showLoader() {
        this.modalBodyTarget.innerHTML = this.loadingBody;
        this.submitButtonTarget.innerHTML = this.loadingSubmitButtonText;
    }

    async openModal(event) {
        if (typeof event.currentTarget.href !== "undefined") {
            this.formUrlValue = event.currentTarget.href;
            event.preventDefault();
            event.stopImmediatePropagation();
        } else if (event.currentTarget.hasAttribute('data-url')) {
            this.formUrlValue = event.currentTarget.getAttribute('data-url');
        }

        this.showLoader();
        this.modal = new Modal(this.modalTarget);
        this.modal.show();

        const response = await fetch(this.formUrlValue);
        this.modalBodyTarget.innerHTML = await response.text();
    }

    updateFooterHelper(event) {
        this.footerHelperTarget.innerHTML = event.detail;
    }

    async submitForm(event) {
        event.preventDefault();
        const form = this.modalBodyTarget.getElementsByTagName('form')[0];
        let data = {
            headers: new Headers({'X-STIMULUS-REQUEST': true}),
            method: form.method,
            body: new URLSearchParams(new FormData(form)),
        };

        this.showLoader();
        const response = await fetch(this.formUrlValue, data);

        if (response.ok) {
            if (response.headers.has('REFRESH-PAGE')) {
                location.reload();
            }

            if (response.headers.has('SUBMIT_BUTTON-TEXT')) {
                this.submitButtonTarget.innerText = response.headers.get('SUBMIT_BUTTON-TEXT')
            }

            if (!response.headers.has('KEEP-MODAL-OPEN')) {
                this.modal.hide();
            }
        }
        this.modalBodyTarget.innerHTML = await response.text();
    }
    close() {
        this.modal.hide();
    }

    disableLinkButton() {
        this.linkButtonTarget.classList.add('disabled');
    }
    enableLinkButton() {
        this.linkButtonTarget.classList.remove('disabled');
    }
}
