import {Controller} from '@hotwired/stimulus';
import Swal from 'sweetalert2';
import { Collapse } from "bootstrap";

export default class extends Controller {
    static values = {
        url: String,
        confirmDeleteTitle: String,
        confirmDeleteText: String,
        confirmDeleteIcon: String,
        confirmDeleteConfirmButtonText: String,
        confirmDeleteCancelButtonText: String,
    }

    confirm() {
        Swal.fire({
            title: this.confirmDeleteTitleValue || null,
            text: this.confirmDeleteTextValue || null,
            icon: this.confirmDeleteIconValue || 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#',
            confirmButtonText: this.confirmDeleteConfirmButtonTextValue || 'Yes',
            cancelButtonText: this.confirmDeleteCancelButtonTextValue || 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.remove(this.urlValue, this.element);
            }
        });
    }

    async remove(url, element) {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'}
        });
        console.log(response);

        if (response.ok) {
            element.remove();
            return true;
        } else {
            return false;
        }
    }
}
