import {Controller} from '@hotwired/stimulus';
import {Tab} from 'bootstrap'

export default class extends Controller {
    static values = {
        to: String,
    }

    go() {
        const triggerEl = document.querySelector(this.toValue);

        let tab = new Tab(triggerEl);

        tab.show();
    }
}
